<template>
    <b-card no-body>
        <b-tabs small card>
            <custom-o-j-t-page active/>
            <custom-m-a-page/>
        </b-tabs>
    </b-card>
</template>
<script>

import {Vue, Component} from 'vue-property-decorator';
import Breadcrumb from '@/views/menu/breadcrumb/breadcrumb';
import store from '@/store/store';
import CustomMAPage from '@/views/private/activities/custom/CustomMAPage.vue';
import CustomOJTPage from '@/views/private/activities/custom/CustomOJTPage.vue';
import {errorToastOptions} from "../../../../util/formatters";

@Component({
    components: {
        CustomMAPage,
        CustomOJTPage
    }
})
export default class CustomActivities extends Vue {
    get activeUser() {
        return this.$store.getters['userSession/getUser'];
    }

    get canReview() {
        return this.activeUser.isAnAdministrator() || this.activeUser.isAnInstructor();
    }

    async beforeRouteEnter(to, from, next) {
        store.commit('addBreadcrumb', Breadcrumb.create('Custom Activities', null, true));
        try {
            await store.dispatch('activities/loadOJTCategories');
        }
        catch (error) {
            const vm = new Vue();
            vm.$bvToast.toast(error.message, errorToastOptions);
        }
        next();
    }
}
</script>
<style scoped>

</style>