<template>
    <b-tab title="Custom On-the-Job Training" :active="active" lazy>
        <custom-activity-table activity-type="OJT"/>
    </b-tab>
</template>
<script>

import {Vue, Component, Prop} from 'vue-property-decorator';
import CustomActivityTable from '@/views/private/activities/custom/CustomActivityTable.vue';
import {errorToastOptions} from "../../../../util/formatters";
import store from '@/store/store';

@Component({
    components: {
        CustomActivityTable
    }
})
export default class CustomOJTPage extends Vue {
    @Prop({type: Boolean, default: false}) active;

    async mounted() {
        //OJT categories may already loaded when OJTPage mounts, but they are cached
        try {
            await store.dispatch('activities/loadOJTCategories');
        }
        catch (error) {
            this.$bvToast.toast(error.message, errorToastOptions);
        }
    }
}

</script>
<style scoped>

</style>