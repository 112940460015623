<template>
    <b-row>
        <b-col>
            <b-row class="mb-2">
                <b-col>
                    <b-card no-body>
                        <b-card-header class="clearfix" @click="filtersExpanded = !filtersExpanded">
                            <strong>Filter Activities</strong>
                            <div class="float-right">
                                <font-awesome-icon :icon="filtersExpanded ? 'angle-double-up' : 'angle-double-down'"/>
                            </div>
                        </b-card-header>
                        <b-collapse v-model="filtersExpanded">
                            <b-card-body>
                                <b-row>
                                    <b-col cols="md-3">
                                        <b-form-group size="sm">
                                            <template v-slot:label><strong>ID:</strong></template>
                                            <b-input-group size="sm">
                                                <b-input placeholder="Filter Display ID" v-model="filters.displayId"/>
                                                <b-input-group-addon>
                                                    <b-button @click="filters.displayId = null">
                                                        <font-awesome-icon prefix="fas" icon="times"/>
                                                    </b-button>
                                                </b-input-group-addon>
                                            </b-input-group>
                                        </b-form-group>
                                    </b-col>
                                    <b-col cols="md-3">
                                        <b-form-group size="sm">
                                            <template v-slot:label><strong>Title:</strong></template>
                                            <b-input-group size="sm">
                                                <b-input placeholder="Filter Title" v-model="filters.title"/>
                                                <b-input-group-addon>
                                                    <b-button @click="filters.title = null">
                                                        <font-awesome-icon prefix="fas" icon="times"/>
                                                    </b-button>
                                                </b-input-group-addon>
                                            </b-input-group>
                                        </b-form-group>
                                    </b-col>
                                    <b-col cols="md-3">
                                        <b-form-group size="sm">
                                            <template v-slot:label><strong>Created:</strong></template>
                                            <picker-of-dates range v-model="filters.created" :clearable="true"/>
                                        </b-form-group>
                                    </b-col>
                                    <b-col cols="md-3">
                                        <b-form-group>
                                            <template v-slot:label><strong>Created By:</strong></template>
                                            <b-input-group size="sm">
                                                <b-input placeholder="Filter Created By" v-model="filters.createdBy"/>
                                                <b-input-group-addon>
                                                    <b-button @click="filters.createdBy = null">
                                                        <font-awesome-icon prefix="fas" icon="times"/>
                                                    </b-button>
                                                </b-input-group-addon>
                                            </b-input-group>
                                        </b-form-group>
                                    </b-col>
                                    <b-col cols="md-3">
                                        <b-form-group>
                                            <template v-slot:label><strong>Status:</strong></template>
                                            <b-input-group size="sm">
                                                <b-select v-model="filters.status" :options="statusOptions">
                                                    <template v-slot:first>
                                                        <option :value="null"> - </option>
                                                    </template>
                                                </b-select>
                                                <b-input-group-addon>
                                                    <b-button @click="filters.status = null">
                                                        <font-awesome-icon prefix="fas" icon="times"/>
                                                    </b-button>
                                                </b-input-group-addon>
                                            </b-input-group>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                            </b-card-body>
                        </b-collapse>
                    </b-card>
                </b-col>
            </b-row>

            <b-row>
                <b-col cols="4">
                    <b-pagination :total-rows="tableData.length" :per-page="pageSize" v-model="currentPage"/>
                </b-col>
                <b-col cols="8">
                    <b-form-group :label="'Records Per Page: ' + pageSize">
                        <b-input type="range" v-model="pageSize" min="10" max="100"></b-input>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-button-group size="sm" class="float-right">
                        <b-button variant="dark" @click="submitNew">Submit New Custom {{activityType | typeToName}}</b-button>
                        <b-button variant="success" @click="refresh">Refresh</b-button>
                    </b-button-group>
                </b-col>
            </b-row>

            <b-row>
                <b-col>
                    <b-table small striped borderless outlined head-variant="dark" stacked="md"
                             :fields="fields"
                             :items="tableData"
                             :per-page="pageSize"
                             :sort-by.sync="sort.by"
                             :sort-desc.sync="sort.desc"
                             no-local-sorting
                             :current-page="currentPage">

                        <template v-slot:head(buttons)>
                            <div class="text-center">
                                <b-checkbox v-model="filters.showActive">Show Only Active</b-checkbox>
                            </div>
                        </template>

                        <template v-slot:cell(id)="row">{{row.item.customDisplayId}}</template>
<!--                        <template v-slot:cell(id)="row">{{activityType}}-CUST-{{row.item.customActivityId}}</template>-->

                        <template v-slot:cell(created)="row">{{row.item.created | date}}</template>
                        <template v-slot:cell(createdBy)="row">{{formatCreator(row.item.creator)}}</template>

                        <template v-slot:cell(status)="row">
                            <custom-activity-status-badge :status="row.item.status"/>
                        </template>

                        <template v-slot:cell(buttons)="row">
                            <div class="table-buttons">
                                <b-button class="p-0 m-0" size="sm" variant="link" :disabled="!isAssignable(row.item)" @click="assignToTrainee(row.item)">assign to trainees</b-button> |
                                <b-button class="p-0 m-0" size="sm" variant="link" @click="row.toggleDetails">details</b-button>
                                <template v-if="isPromotable(row.item)">
                                    | <b-button class="p-0 m-0" size="sm" variant="link" @click="promote(row.item)">promote</b-button>
                                </template>
                            </div>
                        </template>
                        <template v-slot:row-details="row">
                            <custom-activity-table-details :activity="row.item"/>
                        </template>
                    </b-table>
                </b-col>
            </b-row>
        </b-col>
        <new-custom-activity-dialog ref="new-activity-dialog" :activity-type="activityType" @new-activity="newActivitySubmitted"/>
        <custom-activity-trainee-assignment ref="assign-trainees-dialog" v-model="selectedActivity"/>
        <custom-activity-promotion-dialog ref="promote-activity-modal" v-model="selectedActivity"/>
    </b-row>
</template>
<script>

import {Vue, Component, Prop} from 'vue-property-decorator';
import _ from 'underscore';
import store from '@/store/store';
import {isBefore, isAfter, addDays} from 'date-fns';
import {sprintf} from 'sprintf-js';
import {date, trimToNull} from '@/util/formatters';
import {NULL_USER} from '@/model/user';
import {CustomActivityStatus} from '@/model/activity';
import CustomActivityTableDetails from '@/views/private/activities/custom/CustomActivityTableDetails.vue';
import CustomActivityStatusBadge from '@/views/private/activities/custom/CustomActivityStatusBadge.vue';
import NewCustomActivityDialog from '@/views/private/activities/custom/NewCustomActivityDialog.vue';
import CustomActivityTraineeAssignment from '@/views/private/activities/custom/CustomActivityTraineeAssignment.vue';
import CustomActivityPromotionDialog from '@/views/private/activities/custom/CustomActivityPromotionDialog.vue';
import PickerOfDates from '@/components/shared/PickerOfDates.vue';
import {errorToastOptions} from "../../../../util/formatters";

@Component({
    components: {
        CustomActivityTableDetails,
        CustomActivityStatusBadge,
        NewCustomActivityDialog,
        CustomActivityTraineeAssignment,
        CustomActivityPromotionDialog,
        PickerOfDates
    },
    filters: {
        date: (d) => date(d, 'M/d/yyyy h:mm aaaa'),
        typeToName: (t) => 'OJT' === t ? 'On-the-Job Training' : 'Mentorship Activity'
    }
})
export default class CustomActivityTable extends Vue {
    @Prop({type: String}) activityType;

    filtersExpanded = true;

    filters = {
        displayId: null,
        title: null,
        created: null,
        createdBy: null,
        status: null,
        showActive: false
    };

    sort = {
        by: null,
        desc: null
    };

    pageSize = 20;
    currPage = 1;

    selectedActivity = null;

    get activeUser() {
        return this.$store.getters['userSession/getUser'];
    }

    get currentPage() {
        return this.currPage;
    }

    set currentPage(num) {
        const page = parseInt(num, 10);

        switch (true) {
            case _.isNaN(page):
            case 0 >= page:
                this.currPage = 1;
                break;

            case page > this.numPages:
                this.currPage = this.numPages;
                break;

            default:
                this.currPage = page;
        }
    }

    get numPages() {
        return Math.max(Math.ceil(this.tableData.length / this.pageSize), 1);
    }

    get activities() {

        if (!_.contains(['OJT', 'MA'], this.activityType)) {
            return []
        }

        return this.$store.getters[sprintf('activities/getCustom%ss', this.activityType)] || [];
    }

    get fields() {
        return [{
            key: 'id',
            label: 'Display ID',
            sortable: true
        }, {
            key: 'title',
            label: 'Title',
            sortable: true
        }, {
            key: 'created',
            label: 'Created',
            sortable: true
        }, {
            key: 'createdBy',
            label: 'Created By',
            sortable: true
        }, {
            key: 'status',
            label: 'Status',
            sortable: true
        }, {
            key: 'buttons',
            label: ''
        }];
    }

    get tableData() {

        const activities = _.chain(this.activities)
            .filter((ca) => {
                // Filter on display ID
                const filterValue = trimToNull(this.filters.displayId);
                const filter = !!filterValue ? new RegExp(filterValue, 'i') : null;
                return !filter || filter.test(ca.customDisplayId);
            })
            .filter((ca) => {
                // Filter on title
                const filterValue = trimToNull(this.filters.title);
                const filter = !!filterValue ? new RegExp(filterValue, 'i') : null;
                return !filter || filter.test(ca.title);
            })
            .filter((ca) => {
                // Filter on created date range
                const dates = this.filters.created || [];

                if (_.isEmpty(dates) || 2 < dates.length || null === dates[0] || null === dates[1]) {
                    return true;
                }

                return _.isDate(ca.created) && isAfter(ca.created, dates[0]) && isBefore(ca.created, addDays(dates[1], 1));
            })
            .filter((ca) => {
                // Filter on created by
                const filterValue = trimToNull(this.filters.createdBy);
                const filter = !!filterValue ? new RegExp(filterValue, 'i') : null;

                if (!filter) {
                    return true;
                }

                const creator = ca.creator || {};
                return filter.test(creator.firstName) || filter.test(creator.lastName) || filter.test(creator.username);
            })
            .filter((ca) => !this.filters.status || this.filters.status === ca.status)
            .filter((ca) => !this.filters.showActive || true === ca.active)
            .sortBy((ca) => {
                switch (this.sort.by) {
                    case 'id':
                        return ca.customActivityId;

                    case 'created':
                        return ca.created;
                    case 'createdBy':
                        return this.formatCreator(ca.creator);
                    case 'status':
                        return ca.status;
                    case 'title':
                    default:
                        return ca.title;
                }
            }).value();

        return this.sort.desc ? activities.reverse() : activities;
    }

    get statusOptions() {
        return _.chain(CustomActivityStatus)
            .keys()
            // We don't want to show DECLINED or INVALID as options
            .filter((s) => !_.contains([CustomActivityStatus.DECLINED, CustomActivityStatus.INVALID], s))
            .map((s) => {

                let text;

                switch (s) {
                    case CustomActivityStatus.SUBMITTED:
                        text = 'Pending Approval';
                        break;

                    case CustomActivityStatus.PENDING_REVISION:
                        text = 'Pending Revision';
                        break;

                    case CustomActivityStatus.APPROVED:
                        text = 'Approved';
                        break;

                    default:
                        console.log(s);
                        text = 'This is a bug. You should not see me.';
                        break;
                }

                return {
                    text: text,
                    value: s
                };
            })
            .value()
    }

    isAssignable(activity) {
        return CustomActivityStatus.APPROVED === activity.status && this.isNotPromoted(activity);
    }

    isNotPromoted(activity) {
        return (parseInt(activity.activityId, 10) || 0) === 0;
    }

    isPromotable(activity) {
        return this.userCanPromote && this.isNotPromoted(activity) &&
            _.isEqual(activity.status, CustomActivityStatus.APPROVED);
    }

    get userCanPromote() {
        return this.activeUser.isAnAdministrator() || this.activeUser.isAnInstructor();
    }

    submitNew() {
        this.$refs['new-activity-dialog'].show();
    }

    newActivitySubmitted(activity) {
        this.activities.push(activity);
    }

    formatCreator(c) {
        //Per request by Rocio, do not reveal usernames
        return NULL_USER.equals(c) ? '' : sprintf('%s %s', c.firstName, c.lastName);
        // return NULL_USER.equals(c) ? '' : sprintf('%s %s (%s)', c.firstName, c.lastName, c.username);
    }

    assignToTrainee(activity) {
        this.selectedActivity = activity;
        this.$refs['assign-trainees-dialog'].show();
    }

    promote(activity) {
        this.selectedActivity = activity;
        this.$refs['promote-activity-modal'].show();
    }

    async refresh() {
        if (_.contains(['OJT', 'MA'], this.activityType)) {
            try {
                await this.$store.dispatch(sprintf('activities/loadCustom%ss', this.activityType));
            }
            catch (error) {
                this.$bvToast.toast(error.message, errorToastOptions);
            }
        }
    }

    async mounted() {
        await this.refresh();
    }
}
</script>
<style scoped>
    div.table-buttons {
        white-space: nowrap;
    }
</style>