+<template>
    <b-card  no-body>
        <b-card-body>
            <b-row>
                <b-col>
                    <b-table-lite outlined stacked striped small :fields="tableFields" :items="tableData">
                        <template v-slot:cell(title)>
                            <b-input size="sm" v-model="activity.workspace.title" v-if="editMode"/>
                            <template v-else>{{activity.title}}</template>
                        </template>
                        <template v-slot:cell(description)>
                            <b-textarea size="sm" v-model="activity.workspace.description" v-if="editMode"/>
                            <template v-else>{{activity.description}}</template>
                        </template>
                        <template v-slot:cell(created)>{{activity.created | dateTime }}</template>
                        <template v-slot:cell(createdBy)>{{createdBy}}</template>
                        <template v-slot:cell(status)>
                            <b-dropdown size="sm" v-if="canChangeStatus">
                                <template v-slot:button-content>
                                    <custom-activity-status-badge :status="activity.status"/>
                                </template>
                                <template v-if="'PENDING_REVISION' === activity.status">
                                    <b-dropdown-item variant="dark" @click="approve">Approve</b-dropdown-item>
                                </template>
                                <template v-if="'SUBMITTED' === activity.status">
                                    <b-dropdown-item variant="dark" @click="approve">Approve</b-dropdown-item>
                                    <b-dropdown-item variant="dark" @click="requireRevision">Request Revision</b-dropdown-item>
                                </template>
                                <template v-if="'APPROVED' === activity.status">
                                    <b-dropdown-item variant="dark" @click="requireRevision">Require Revision</b-dropdown-item>
                                </template>
                            </b-dropdown>
                            <custom-activity-status-badge :status="activity.status" v-else/>
                        </template>
                        <template v-slot:cell(contractor)>{{creatingContractor}}</template>
                        <template v-slot:cell(contractorType)>{{contractorType}}</template>
                        <template v-slot:cell(approved)>{{activity.approved | dateTime}}</template>
                        <template v-slot:cell(approvedBy)>{{approvedBy}}</template>
                        <template v-slot:cell(pendingRevision)>{{activity.revision | dateTime}}</template>
                        <template v-slot:cell(pendingRevisionBy)>{{revisionBy}}</template>
                        <template v-slot:cell(trainees)>{{activity.assignedTrainees}}</template>
                        <template v-slot:cell(comments)>
                            <b-row v-if="hasComments && !editCommentMode">
                                <b-col v-html="comment.comment"></b-col>
                            </b-row>
                            <b-row v-if="editCommentMode">
                                <b-col>
                                    <rich-text-editor v-model="editComment.comment"/>
                                </b-col>
                            </b-row>
                            <b-row v-if="canEditComments">
                                <b-col v-if="editCommentMode">
                                    <b-link @click="saveComments">save comments</b-link> |
                                    <b-link @click="editCommentMode = false">cancel</b-link>
                                </b-col>
                                <b-col v-else>
                                    <b-link @click="startEditingComments">edit comments</b-link> |
                                    <b-link @click="showCommentHistory">view history</b-link>
                                </b-col>
                            </b-row>
                        </template>
                    </b-table-lite>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="text-right" v-if="canEdit">
                    <b-button-group size="sm" v-if="editMode">
                        <b-button variant="info" @click="save">Save</b-button>
                        <b-button variant="primary" @click="cancelEdit">Cancel</b-button>
                    </b-button-group>
                    <b-button-group size="sm" v-else>
                        <b-button variant="warning" @click="startEdit">Edit</b-button>
                    </b-button-group>
                </b-col>
            </b-row>
        </b-card-body>
        <comment-history-modal ref="activity-comment-history"
                               :title="'Comments about ' + activity.displayId"
                               v-model="activity.comments"/>

        <b-modal size="xl" ref="status-change-comment">
            <template v-slot:modal-header>
                <strong>A Comment is Required</strong>
                <custom-activity-status-badge :status="stagedStatus"/>
            </template>
            <rich-text-editor v-model="stagedComments"></rich-text-editor>
            <template v-slot:modal-footer="{close}">
                <b-button-group size="sm">
                    <b-button variant="primary" @click="saveStatus">Save</b-button>
                    <b-button variant="secondary" @click="close">Cancel</b-button>
                </b-button-group>
            </template>
        </b-modal>
    </b-card>
</template>
<script>

import {Vue, Prop, Component} from 'vue-property-decorator';
import {CustomActivity} from '@/model/activity';
import {date} from '@/util/formatters';
import userDao from '@/dao/user_dao';
import organizationDao from '@/dao/organization_dao';
import activityDao from '@/dao/activity_dao';
import commentDao from '@/dao/comment_dao';
import store from '@/store/store';
import {sprintf} from 'sprintf-js';
import _ from 'underscore';
import {Organization} from '@/model/organization';
import {NULL_USER} from '@/model/user';
import {CustomActivityStatus} from '@/model/activity';
import {NULL_COMMENT, Comment} from '@/model/comment';
import RichTextEditor from '@/components/shared/RichTextEditor.vue';
import CommentHistoryModal from '@/components/shared/CommentHistoryModal.vue';
import CustomActivityStatusBadge from '@/views/private/activities/custom/CustomActivityStatusBadge.vue';
import {errorModalOptions, errorToastOptions} from "../../../../util/formatters";

@Component({
    components: {
        RichTextEditor,
        CommentHistoryModal,
        CustomActivityStatusBadge
    },

    filters: {
        dateTime: (d) => date(d, 'M/d/yyyy h:m aaaa')
    },

    asyncComputed: {
        async createdBy() {
            try {
                const user = await userDao.getUserById(this.activity.creatorId);
                return _.isNull(user) || NULL_USER.equals(user) ? '' : sprintf('%s %s (%s)',
                    user.firstName,
                    user.lastName,
                    user.username
                );
            }
            catch (error) {
                this.$bvToast.toast(error.message, errorToastOptions);
            }
        },

        async approvedBy() {
            try {
                const user = await userDao.getUserById(this.activity.approverId);
                return _.isNull(user) || NULL_USER.equals(user) ? '' : sprintf('%s %s (%s)',
                    user.firstName,
                    user.lastName,
                    user.username
                );
            }
            catch (error) {
                this.$bvToast.toast(error.message, errorToastOptions);
            }
        },

        async revisionBy() {
            try {
                const user = await userDao.getUserById(this.activity.reviserId);
                return _.isNull(user) || NULL_USER.equals(user) ? '' : sprintf('%s %s (%s)',
                    user.firstName,
                    user.lastName,
                    user.username
                );
            }
            catch (error) {
                this.$bvToast.toast(error.message, errorToastOptions);
            }
        },

        async contractor() {
            try {
                return await organizationDao.getOrganizationById(this.activity.organizationId);
            }
            catch (error) {
                this.$bvToast.toast(error.message, errorToastOptions);
            }
        },

        async creatingContractor() {
            const org = await this.contractor;
            return org instanceof Organization ? org.name : '';
        },

        async contractorType() {
            const org = await this.contractor;
            return ((org || {}).type || {}).type;
        }
    }
})
export default class CustomActivityTableDetails extends Vue {
    @Prop({type: CustomActivity}) activity;

    stagedStatus = null;
    stagedComments = null;

    editCommentMode = false;
    editComment = NULL_COMMENT.clone();

    get activeUser() {
        return this.$store.getters['userSession/getUser'];
    }

    get comment() {
        return _.chain(this.activity.comments)
            .filter((c) => _.isObject(c))
            .sortBy((c) => c.lastUpdated)
            .last()
            .value();
    }

    get hasComments() {
        return !_.isEmpty(this.activity.comments);
    }

    get canEditComments() {
        return this.activeUser.isAnAdministrator() || this.activeUser.isAnInstructor();
    }

    get editMode() {
        return this.activity.editMode;
    }

    get canEdit() {
        // The ability to modify  >this< activity
        // The active user must be an administrator or instructor
        // Or if you are the mentor who created >this< activity AND its status is NEEDS REVISION

        const activeUser = this.activeUser;

        switch (true) {
            case activeUser.isAnAdministrator():
            case activeUser.isAnInstructor():
                return true;

            case activeUser.isAMentor():
                return this.activity.creatorId === activeUser.id && _.contains([
                    CustomActivityStatus.SUBMITTED,
                    CustomActivityStatus.PENDING_REVISION
                ], this.activity.status);
        }
    }

    get canChangeStatus() {
        return this.activeUser.isAnInstructor() || this.activeUser.isAnAdministrator();
    }

    get tableFields() {

        const fields = [{
            key: 'title',
            label: 'Title:'
        }, {
            key: 'description',
            label: 'Description:'
        }, {
            key: 'created',
            label: 'Created:'
        }, {
            key: 'createdBy',
            label: 'Created By:'
        }, {
            key: 'status',
            label: 'Status:'
        }, {
            key: 'contractor',
            label: 'Contractor:'
        }, {
            key: 'contractorType',
            label: 'Contractor Type:'
        }, {
            key: 'approved',
            label: 'Approved:'
        }, {
            key: 'approvedBy',
            label: 'Approved By:'
        }, {
            key: 'pendingRevision',
            label: 'Pending Revision:'
        }, {
            key: 'pendingRevisionBy',
            label: 'Pending Revision By:'
        }];

        const user = this.activeUser;

        // We only want to show the comments if the active user
        // is an instructor or an admin, or if the active user
        // created >this< activity and the activity has not yet been approved
        if (user.isAnInstructor() || user.isAnAdministrator() ||
            (user.id === this.activity.creatorId && this.activity.status !== CustomActivityStatus.APPROVED)
        ) {
            fields.push({
                key: 'comments',
                label: 'Comments:'
            });
        }

        fields.push({
            key: 'trainees',
            label: 'Trainees:'
        });

        return fields;
    }

    tableData = [{}];


    startEdit() {
        this.activity.rollback();
        this.activity.editMode = true;
    }

    cancelEdit() {
        this.activity.rollback();
    }

    startEditingComments() {
        this.editComment.recordId = this.activity.id;
        this.editComment.comment = (this.comment || {}).comment || '';
        this.editCommentMode = true;
    }

    showCommentHistory() {
        this.$refs['activity-comment-history'].show();
    }

    save() {

        this.stagedComments = (this.comment || {}).comment || '';
        this.stagedStatus = CustomActivityStatus.SUBMITTED;
        this.$refs['status-change-comment'].show();
    }

    async approve() {

        if (!this.activeUser.isAnInstructor() && !this.activeUser.isAnAdministrator()) {
            return;
        }

        this.stagedComments = (this.comment || {}).comment || '';
        this.stagedStatus = CustomActivityStatus.APPROVED;
        this.saveStatus();
    }

    decline() {
        console.log('decline');
    }

    requireRevision() {

        if (!this.activeUser.isAnInstructor() && !this.activeUser.isAnAdministrator()) {
            return;
        }

        this.stagedComments = (this.comment || {}).comment || '';
        this.stagedStatus = CustomActivityStatus.PENDING_REVISION;
        this.$refs['status-change-comment'].show();
    }

    async saveStatus() {
        this.activity.workspace.status = this.stagedStatus;

        const commentRequired = _.contains([
            CustomActivityStatus.SUBMITTED,
            CustomActivityStatus.PENDING_REVISION
        ], this.stagedStatus);

        if (commentRequired) {
            this.activity.workspace.comment = new Comment({
                recordId: this.activity.id,
                comment: this.stagedComments
            });
        }
        try {
            const savedActivity = await activityDao.saveCustomActivity(this.activity.workspace);

            this.activity.workspace.status = savedActivity.status;
            this.activity.commit();

            if (commentRequired) {
                this.activity.comments.push(savedActivity.comment);
            }

            this.$refs['status-change-comment'].hide();

        }
        catch (error) {
            await this.$bvModal.msgBoxOk(error.message, errorModalOptions);
            this.activity.rollback();
        }
    }

    async saveComments() {
        try {
            const savedComment = await commentDao.saveComment(this.editComment.cloneTemplate(), 'activity');
            this.activity.comments.push(savedComment);
            this.editCommentMode = false;
        }
        catch (error) {
            await this.$bvModal.msgBoxOk(error.message, errorModalOptions);
        }
    }

    async mounted() {
        try {
            this.activity.comments = await activityDao.getCustomActivityComments(this.activity);
        }
        catch (error) {
            this.$bvToast.toast(error.message, errorToastOptions);
        }
    }
}

</script>
<style scoped>

</style>