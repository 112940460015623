<template>
    <b-modal ref="new-custom-activity-dialog" size="md">
        <template v-slot:modal-header>
            <strong>Submit New Custom {{activityType | typeToName}}</strong>
        </template>
        <b-row>
            <b-col>
                <b-form-group label-size="sm" label="Title:" invalid-feedback="Title is required" :state="isValid('title')">
                    <b-input size="sm" v-model="title" :state="isValid('title')"/>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-form-group label-size="sm" label="Description:" invalid-feedback="Description is required" :state="isValid('description')">
                    <b-textarea size="sm" v-model="description" :state="isValid('description')"/>
                </b-form-group>
            </b-col>
        </b-row>
        <template v-slot:modal-footer="{ close }">
            <b-button-group size="sm">
                <b-button variant="primary" @click="save">Submit</b-button>
                <b-button @click="close">Cancel</b-button>
            </b-button-group>
        </template>
    </b-modal>
</template>
<script>

import {Vue, Prop, Component} from 'vue-property-decorator';
import activityDao from '@/dao/activity_dao';
import {CustomActivity, CustomActivityStatus} from '@/model/activity';
import {trimToEmpty, trimToNull} from '@/util/formatters';
import _ from 'underscore';
import {errorModalOptions} from "../../../../util/formatters";
import store from '@/store/store';

@Component({
    filters: {
        typeToName: (t) => 'OJT' === t ? 'On-the-Job Training' : 'Mentorship Activity'
    }
})
export default class NewCustomActivityDialog extends Vue {

    @Prop({type: String}) activityType;

    titleValue = null;
    descriptionValue = null;

    get title() {
        return trimToEmpty(this.titleValue);
    }

    set title(title) {
        this.titleValue = trimToNull(title);
    }

    get description() {
        return trimToEmpty(this.descriptionValue);
    }

    set description(description) {
        this.descriptionValue = trimToNull(description);
    }

    get activeUser() {
        return this.$store.getters['userSession/getUser'];
    }

    show() {
        this.title = null;
        this.description = null;
        this.$refs['new-custom-activity-dialog'].show();
    }

    isValid(field) {
        return !_.isEmpty(this[field]);
    }

    async save() {
        if (this.activeUser.isATrainee()) {
            return;
        }
        const activity = CustomActivity.create({
            type: this.activityType,
            status: CustomActivityStatus.SUBMITTED,
            title: this.title,
            description: this.description
        });
        try {
            const savedActivity = await activityDao.saveCustomActivity(activity);

            this.$emit('new-activity', savedActivity);
            this.$refs['new-custom-activity-dialog'].hide();
        }
        catch (error) {
            await this.$bvModal.msgBoxOk(error.message, errorModalOptions);
        }
    }
}
</script>
<style scoped>



</style>