<template>
    <b-modal ref="promote-activity-dialog" @hidden="modalHidden">
        <template v-slot:modal-header>
            <h3><strong>Promote {{activity.title}} to a Standard {{activity.type}}</strong></h3>
        </template>
        <b-row>
            <b-col cols="md-4">
                <b-form-group invalid-feedback="Display ID is Required." :state="!hasError('displayId')">
                    <template v-slot:label><strong>Display ID:</strong></template>
                    <display-id :prefix="displayIdPrefix" v-model="displayId" show-state/>
                </b-form-group>
            </b-col>
            <b-col cols="md-8">
                <b-form-group :invalid-feedback="('OJT' === activity.type ? 'Category' : 'Title') + ' is Required.'"
                              :state="!hasError('categoryId') && !hasError('title')">
                    <template v-slot:label><strong>{{'OJT' === activity.type ? 'Category' : 'Title'}}:</strong></template>
                    <OJTCategorySelect size="sm" show-state show-first :generation="2"
                                       v-model="categoryId"
                                       v-if="'OJT' === this.activity.type"/>
                    <b-input size="sm" placeholder="Title" v-model="title" :state="!hasError('title')"v-else/>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-form-group invalid-feedback="Description is Required." :state="!hasError('description')">
                    <template v-slot:label><strong>Description:</strong></template>
                    <b-textarea size="sm" v-model="description" :state="!hasError('description')"/>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-form-group>
                    <template v-slot:label><strong>Recommended Time:</strong></template>
                    <b-input size="sm" placeholder="Recommended Time" v-model="recommendedTime"/>
                </b-form-group>
            </b-col>
        </b-row>
        <template v-if="'OJT' === this.activity.type">
            <b-row>
                <b-col>
                    <b-form-group>
                        <template v-slot:label><strong>URL:</strong></template>
                        <b-input size="sm" placeholder="URL" v-model="url"/>
                    </b-form-group>
                </b-col>
            </b-row>
        </template>
        <template v-if="'MA' === this.activity.type">
            <b-row>
                <b-col>
                    <b-form-group>
                        <template v-slot:label><strong>Suggestions:</strong></template>
                        <b-form-file :accept="allowedAttachmentTypes"
                                     placeholder="Attach files"
                                     drop-placeholder="Drop file here"
                                     v-model="suggestions" />
<!--                        <b-input size="sm" placeholder="Suggestions"/>-->
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-form-group>
                        <template v-slot:label><strong>Reference:</strong></template>
                        <b-form-file :accept="allowedAttachmentTypes"
                                     placeholder="Attach files"
                                     drop-placeholder="Drop file here"
                                     v-model="reference" />
<!--                        <b-input size="sm" placeholder="Reference"/>-->
                    </b-form-group>
                </b-col>
            </b-row>
        </template>
        <template v-slot:modal-footer="{close}">
            <b-button-group size="sm">
                <b-button variant="success" @click="promote">Promote</b-button>
                <b-button variant="secondary" @click="close">Cancel</b-button>
            </b-button-group>
        </template>
    </b-modal>
</template>
<script>

import {Vue, Prop, Component} from 'vue-property-decorator';
import {CustomActivity} from '@/model/activity';
import DisplayId from '@/views/private/activities/DisplayId.vue';
import OJTCategorySelect from '@/views/private/activities/OJTCategorySelect.vue';
import _ from 'underscore';
import store from '@/store/store';
import activityDao from '@/dao/activity_dao';
import {errorModalOptions} from "../../../../util/formatters";
import {sprintf} from 'sprintf-js';

@Component({
    components: {
        DisplayId,
        OJTCategorySelect
    }
})
export default class CustomActivityPromotionDialog extends Vue {
    @Prop({type: CustomActivity}) value;
    reference = null;
    suggestions = null;

    get allowedAttachmentTypes() {
        return '.jpg, .jpeg, .png, .gif, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .txt, .pdf, .tiff, .rtf, .log';
    }

    get displayIdPrefix() {
        return 'OJT' === this.activity.type ? 'OJT' : 'M'
    }

    get displayId() {
        const dId = (this.activity.workspace || {}).displayId || '';
        const num = parseInt(dId.replace(/^(OJT|MA?)-(\d+)$/, '$2'), 10) || 0;
        return sprintf('%s-%d', this.displayIdPrefix, num);
    }

    set displayId(dId) {
        this.activity.workspace.displayId = dId;
    }

    get title() {
        return (this.activity.workspace || {}).title;
    }

    set title(title) {
        this.activity.workspace.title = title;
    }

    get categoryId() {
        return parseInt((this.activity.workspace || {}).categoryId, 10) || null;
    }

    set categoryId(categoryId) {
        this.activity.workspace.categoryId = categoryId;
    }

    get description() {
        return (this.activity.workspace || {}).description;
    }

    set description(description) {
        this.activity.workspace.description = description;
    }

    get recommendedTime() {
        return (this.activity.workspace || {}).recommendedTime;
    }

    set recommendedTime(rt) {
        this.activity.workspace.recommendedTime = rt;
    }

    get url() {
        return (this.activity.workspace || {}).url;
    }

    set url(url) {
        this.activity.workspace.url = url;
    }

    get activity() {
        return this.value || {};
    }

    show() {
        this.$refs['promote-activity-dialog'].show();
    }

    hide() {
        this.$refs['promote-activity-dialog'].hide();
    }

    hasError(field) {
        switch (field) {
            case 'displayId':
                return 0 >= (parseInt(this.displayId.replace(/^(OJT|MA?)-(\d+)$/, '$2'), 10) || 0);

            case 'title':
                return 'MA' === this.activity.type && _.isEmpty(this.title);

            case 'categoryId':
                return 'OJT' === this.activity.type && null === this.categoryId;

            case 'description':
                return _.isEmpty(this[field]);

            default:
                return false;
        }
    }

    async promote() {

        try {

            if (_.any(['displayId', 'title', 'categoryId', 'description'], (f) => this.hasError(f))) {
                return this.$bvModal.msgBoxOk('Please correct the highlighted errors.', {
                    size: 'sm',
                    buttonSize: 'sm'
                });
            }

            const toPromote = {
                id: this.activity.id,
                displayId: this.displayId,
                type: this.activity.type,
                title: this.title,
                categoryId: this.categoryId,
                description: this.description,
                recommendedTime: this.recommendedTime,
                url: this.url,
                suggestions: this.suggestions,
                reference: this.reference
            };

            const activityId = await activityDao.promote(toPromote);

            this.activity.activityId = activityId;

            this.$bvModal.msgBoxOk(sprintf('%s has been promoted!', this.activity.title), {
                size: 'sm',
                buttonSize: 'sm'
            }).then(() => {
                this.hide();
            });
        } catch (error) {
            await this.$bvModal.msgBoxOk(error.message, errorModalOptions);
        }
    }

    modalHidden() {
        this.activity.rollback();
    }
}
</script>
<style scoped>

</style>