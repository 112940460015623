<template>
    <b-tab title="Custom Mentorship Activities" :active="active" lazy>
        <custom-activity-table activity-type="MA"/>
    </b-tab>
</template>
<script>
import {Component, Vue, Prop} from 'vue-property-decorator';
import CustomActivityTable from '@/views/private/activities/custom/CustomActivityTable.vue';
import store from '@/store/store';

@Component({
    components: {
        CustomActivityTable
    }
})
export default class CustomMAPage extends Vue {
    @Prop({type: Boolean, default: () => false}) active;

}
</script>
<style scoped>

</style>
