<template>
    <b-modal ref="comment-history-modal" scrollable centered size="lg" button-size="sm" :title="title">
        <b-table-lite small head-variant="dark" bordered sticky-header striped :fields="fields" :items="comments">
            <template v-slot:cell(comment)="row">
                <span v-html="row.item.comment"></span>
            </template>
            <template v-slot:cell(lastUpdated)="row">{{ row.item.lastUpdated | lastUpdate }}</template>
        </b-table-lite>
        <template v-slot:modal-footer="{ ok }">
            <b-button size="sm" variant="primary" @click="ok">Close</b-button>
        </template>
    </b-modal>
</template>
<script>

import {Vue, Prop, Component} from 'vue-property-decorator';
import formatters from '@/util/formatters';
import _ from 'underscore';

@Component({
    filters: {
        lastUpdate: (lastUpdate) => formatters.date(lastUpdate, 'M/d/yyyy h:mm:ss aaaa')
    }
})
export default class CommentHistoryModal extends Vue {
    @Prop({type: Array}) value;
    @Prop({type: String}) title;

    get comments() {
        return _.chain(this.value)
            .filter((c) => _.isObject(c))
            .sortBy((c) => c.lastUpdated)
            .value()
            .reverse();
    }

    get fields() {
        return [{
            key: 'comment',
            label: 'Comment'
        }, {
            key: 'updatedBy',
            label: 'Username'
        }, {
            key: 'lastUpdated',
            label: 'Updated'
        }];
    }

    show() {
        this.$refs['comment-history-modal'].show();
    }
}
</script>
<style scoped>

</style>