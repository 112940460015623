<template>
    <div>
        <b-modal ref="assign-trainees-modal" size="lg" scrollable>
            <template v-slot:modal-header>
                <strong>Assign Trainees to {{activity.title}}</strong>
            </template>
            <b-row>
                <b-col cols="md-3">
                    <b-row>
                        <b-col>
                            <b-form-group>
                                <template v-slot:label><strong>Search UBC ID:</strong></template>
                                <b-input-group size="sm">
                                    <ubc-id input-class="form-control form-control-sm" v-model="criteria.ubcId" @submitted="doSearch"/>
                                    <b-input-group-addon>
                                        <b-button @click="criteria.ubcId = null"><font-awesome-icon icon="times"/></b-button>
                                    </b-input-group-addon>
                                </b-input-group>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <b-form-group>
                                <template v-slot:label><strong>Search Last Name:</strong></template>
                                <b-input-group size="sm">
                                    <b-input v-model="criteria.lastname" placeholder="Last Name" @keyup.enter="doSearch"/>
                                    <b-input-group-addon>
                                        <b-button @click="criteria.lastname = null"><font-awesome-icon icon="times"/></b-button>
                                    </b-input-group-addon>
                                </b-input-group>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <b-button-group size="sm">
                                <b-button variant="primary" @click="doSearch">Search</b-button>
                                <b-button variant="info">Clear</b-button>
                            </b-button-group>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col cols="md-9">
                    <b-row>
                        <b-col cols="md-4">
                            <b-form-group>
                                <template v-slot:label>
                                    <strong>Filter UBC ID:</strong>
                                </template>
                                <b-input-group size="sm">
                                    <ubc-id input-class="form-control form-control-sm" v-model="tableFilter.ubcId"/>
                                    <b-input-group-addon>
                                        <b-button @click="tableFilter.ubcId = null"><font-awesome-icon icon="times"/></b-button>
                                    </b-input-group-addon>
                                </b-input-group>
                            </b-form-group>
                        </b-col>
                        <b-col cols="md-4">
                            <b-form-group>
                                <template v-slot:label>
                                    <strong>Filter Name:</strong>
                                </template>
                                <b-input-group size="sm">
                                    <b-input v-model="tableFilter.name" placeholder="Name"/>
                                    <b-input-group-addon>
                                        <b-button @click="tableFilter.name = null"><font-awesome-icon icon="times"/></b-button>
                                    </b-input-group-addon>
                                </b-input-group>
                            </b-form-group>
                        </b-col>
                        <b-col cols="md-4">
                            <b-form-group>
                                <template v-slot:label>
                                    <strong>Filter Contractor:</strong>
                                </template>
                                <b-input-group size="sm">
                                    <b-input v-model="tableFilter.contractor" placeholder="Contractor"/>
                                    <b-input-group-addon>
                                        <b-button @click="tableFilter.contractor = null"><font-awesome-icon icon="times"/></b-button>
                                    </b-input-group-addon>
                                </b-input-group>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row v-if="1 < numPages">
                        <b-col cols="md-9">
                            <b-pagination v-model="currentPage" :total-rows="results.length" :per-page="perPage" small/>
                        </b-col>
                        <b-col cols="md-3" class="text-right">
                            Page {{currentPage}} of {{numPages}}
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <b-table head-variant="dark" stacked="md" small striped outlined borderless no-local-sorting
                                     :fields="fields" :items="results"
                                     @row-selected="traineeSelected"
                                     :sort-by.sync="tableSort.by" :sort-desc.sync="tableSort.desc"
                                     selectable select-mode="multi"
                                     :per-page="perPage"
                                     :current-page="currentPage">
                                <template v-slot:cell(selected)="{rowSelected}">
                                    <template v-if="rowSelected">&check;</template>
                                </template>
                                <template v-slot:cell(name)="row">{{row.item.fullname}} {{ row.item.id }}</template>
                                <template v-slot:cell(contractor)="row">{{row.item.organization | contractor}}</template>
                            </b-table>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
            <template v-slot:modal-footer="{ close }">
                <b-button-group size="sm">
                    <b-button variant="success" @click="assignSelected" :disabled="0 === selectedTrainees.length">Assign Selected</b-button>
                    <b-button variant="secondary" @click="close">Close</b-button>
                </b-button-group>
            </template>
        </b-modal>
        <b-modal ref="assign-date-modal" button-size="sm" scrollable>
            <template v-slot:modal-header>
                <strong>{{activity.type}}-Cust {{activity.title}}</strong>
            </template>
            <b-row>
                <b-col>
                    <ol>
                        <template v-for="trainee in selectedTrainees">
                            <li>
                                {{trainee.fullname}} ({{trainee.ubcId}})
                            </li>
                        </template>
                    </ol>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-form-group>
                        <template v-slot:label><strong>Scheduled Date:</strong></template>
                        <CITFDatePicker format="MMMM YYYY"
                                        type="month"
                                        v-model="scheduledDate"/>
                    </b-form-group>
                </b-col>
            </b-row>

            <template v-slot:modal-footer="{ close }">
                <b-button-group size="sm">
                    <b-button variant="success" @click="doAssignment">Assign</b-button>
                    <b-button @click="close">Cancel</b-button>
                </b-button-group>
            </template>
        </b-modal>
    </div>
</template>
<script>

import {Vue, Prop, Component} from 'vue-property-decorator';
import {CustomActivity} from '@/model/activity';
import {UbcId} from '@/components/shared/UbcId.vue';
import {SecurityLevel} from '@/model/security_level';
import _ from 'underscore';
import store from '@/store/store';
import activityDao from '@/dao/activity_dao';
import {trimToEmpty, trimToNull} from '@/util/formatters';
import CITFDatePicker from '@/components/shared/CITFDatePicker';
import {addMonths} from 'date-fns';
import formatters from '@/util/formatters';
import {errorModalOptions, errorToastOptions} from "../../../../util/formatters";

@Component({
    components: {
        UbcId,
        CITFDatePicker
    },

    filters: {
        contractor: (c) => trimToEmpty((c || {}).name)
    }
})
export default class CustomActivityTraineeAssignment extends Vue {
    @Prop({type: CustomActivity}) value;

    currPage = 1;
    perPage = 10;

    trainees = [];
    selectedTrainees = [];

    tableFilter = {
        ubcId: null,
        name: null,
        contractor: null
    };

    tableSort = {
        by: 'name',
        desc: false
    };

    scheduledDate = new Date();

    get currentPage() {
        return Math.max(this.currPage, 1);
    }

    set currentPage(page) {
        this.currPage = page;
    }

    get numPages() {
        return Math.max(Math.ceil(this.results.length / this.perPage), 1);
    }

    get criteria() {
        return this.$store.getters['users/searchCriteria'];
    }

    get traineeStatuses() {
        return this.$store.getters['trainees/statuses'];
    }

    get enrolledStatus() {
        return _.find(this.traineeStatuses, (s) => s.status === 'Enrolled');
    }

    get activity() {
        return !!this.value ? this.value : {};
    }

    get fields() {
        return [{
            key: 'selected',
            label: 'Assign'
        }, {
            key: 'ubcId',
            label: 'UBC ID',
            sortable: true
        }, {
            key: 'name',
            label: 'Name',
            sortable: true
        }, {
            key: 'contractor',
            label: 'Contractor',
            sortable: true
        }];
    }

    get results() {
        const rows = _.chain(this.trainees)
            .filter((t) => {
                const ubcId = trimToEmpty(this.tableFilter.ubcId).replace(/-/g, '');
                return _.isEmpty(ubcId) || (t.ubcId || '').startsWith(ubcId);
            })
            .filter((t) => {
                const filter = trimToEmpty(this.tableFilter.name);
                const regex = _.isEmpty(filter) ? null : new RegExp(filter, 'i');
                return null === regex || regex.test(t.firstName) || regex.test(t.lastName);
            })
            .filter((t) => {
                const filter = trimToEmpty(this.tableFilter.contractor);
                const regex = _.isEmpty(filter) ? null : new RegExp(filter, 'i');
                return null === regex || regex.test(trimToEmpty((t.organization || {}).name));
            })
            .sortBy((t) => {
                switch (this.tableSort.by) {
                    case 'ubcId':
                        return t.ubcId;

                    case 'contractor':
                        return trimToNull((t.organization || {}).name);

                    case 'name':
                    default:
                        return t.lastName;
                }
            })
            .value();

        return this.tableSort.desc ? rows.reverse() : rows;
    }

    show() {
        this.criteria.clear();
        this.trainees = [];
        this.$refs['assign-trainees-modal'].show();
    }

    traineeSelected(selected) {
        this.selectedTrainees = selected;
    }

    async doSearch() {

        this.trainees = [];

        const id = parseInt(this.activity.id, 10) || 0;

        if (0 >= id) {
            return;
        }

        this.criteria.role = SecurityLevel.TRAINEE;
        this.criteria.traineeStatus = this.enrolledStatus.id;

        try {
            this.trainees = await activityDao.searchEligibleTrainees(id, this.criteria.serialize());
        }
        catch (error) {
            this.$bvToast.toast(error.message, errorToastOptions);
        }
    }

    assignSelected() {
        if (_.isEmpty(this.selectedTrainees)) {
            return;
        }

        this.scheduledDate = addMonths(new Date(), 1);
        this.$refs['assign-date-modal'].show();
    }

    async doAssignment() {

        const id = this.activity.id;
        const traineeIds = _.pluck(this.selectedTrainees, 'id');
        const date = formatters.date(this.scheduledDate, 'yyyyMM');

        try {
            activityDao.assignTraineesToCustomActivity(this.activity.type, id, traineeIds, date);
            this.$bvModal.msgBoxOk('Assignments have been made', {
                buttonSize: 'sm',
                size: 'sm'
            }).then(() => {
                this.$refs['assign-date-modal'].hide();
            });
        }
        catch (error) {
            await this.$bvModal.msgBoxOk(error.message, errorModalOptions);
        }
    }

    async mounted() {
        try {
            await this.$store.dispatch('trainees/loadTraineeStatuses');
        }
        catch (error) {
            this.$bvToast.toast(error.message, errorToastOptions);
        }
        this.criteria.clear();
        this.criteria.role = SecurityLevel.TRAINEE;
    }

}
</script>
<style scoped>

</style>