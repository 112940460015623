<template>
    <b-badge :variant="statusVariant">{{status | status}}</b-badge>
</template>
<script>

import {Vue, Prop, Component} from 'vue-property-decorator';
import {CustomActivityStatus} from '@/model/activity';

@Component({
    filters: {
        status: (s) => {
            switch ((s || '').toUpperCase()) {
                case CustomActivityStatus.APPROVED: return 'Approved';
                case CustomActivityStatus.SUBMITTED: return 'Pending Approval';
                case CustomActivityStatus.PENDING_REVISION:
                case CustomActivityStatus.DECLINED: return 'Pending Revision';
                default: return 'This is a bug.  You should not see me.';
            }
        }
    }
})
export default class CustomActivityStatusBadge extends Vue {
    @Prop({type: String}) status;

    get statusVariant() {
        switch ((this.status || '').toUpperCase()) {
            case CustomActivityStatus.APPROVED: return 'success';
            case CustomActivityStatus.SUBMITTED: return 'info';
            default: return 'warning';
        }
    }
}
</script>
<style scoped>

</style>